import React from "react";

const Footer = () => {
  return (
    <footer id="footer" className="section bg-dark text-white">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 text-center text-sm-start wow fadeInUp">
            <p className="mb-2 mb-lg-0">
              Copyright © 2025{" "}
              <a className="fw-400 text-decoration-none" href="/">
                Robert Więckowski
              </a>
              . All Rights Reserved.
            </p>
          </div>
          <div className="col-lg-6 wow fadeInUp">
            <p className="mb-0 text-center text-sm-end fw-400">
              Powered by{" "}
              <a
                className="text-decoration-none hover"
                href="https://en.reactjs.org/"
              >
                React 18
              </a>
              {/* <a className="fw-400 text-decoration-none hover" href="https://www.dopecode.dev/">
                Robert Więckowski
              </a> */}
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
