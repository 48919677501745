import React, { useEffect, useRef, useState } from "react";
import Isotope from "isotope-layout";
import ProjectDetailsModal from "./ProjectDetailsModal";
const Portfolio = () => {
  // init one ref to store the future isotope object
  const isotope = useRef();
  // store the filter keyword in a state
  const [filterKey, setFilterKey] = useState("*");
  const [imagesLoaded, setimagesLoaded] = useState(0);
  const [selectedProjectDetails, setSelectedProjectDetails] = useState();
  const [isOpen, setIsOpen] = useState(false);

  const htmlElement = document.getElementsByTagName("html")[0];
  const isRtl = htmlElement.getAttribute("dir") === "rtl";

  const filters = {
    DETAILED: "Details",
    // MOCKUPS: "Mockups",
    // YOUTUBE: "Youtube Videos",
    // VIMEO: "Vimeo Videos",
  };

  const types = {
    IMAGE: "image",
    VIDEO: "video",
    DOCUMENT: "document",
  };

  const projectsData = [
    {
      title: "Photoshoot - Photography Booking & Management App",
      type: types.DOCUMENT,
      document: {
        projectInfo:
          "A photography booking and management app for clients to schedule sessions and for photographers to track, update, and manage each session's status efficiently. Simplifies communication, scheduling, and workflow.",
        client: "My private project",
        technologies:
          "React Native (Expo 52), Expo Router, Supabase, React Query, Reanimated, Yup + Formik, Day.js,",
        industry: "Private space",
        date: "March, 2025",
        // url: {
        //   name: "Front-end code repository",
        //   link: "https://gitfront.io/r/user-6891110/dfTyuPX98tBm/B-o-o-k-s-t-o-r-e-front-gitfront/ ",
        // },
        sliderImages: [
          "images/projects/Projekt-4.webp",
          "images/projects/Projekt-3.webp",
          "images/projects/Projekt-5.webp",
          "images/projects/Projekt-6.webp",
          "images/projects/Projekt-7.webp",
          "images/projects/Projekt-8.webp",
          "images/projects/Projekt-9.webp",
          "images/projects/Projekt-17.webp",
          "images/projects/Projekt-10.webp",
          "images/projects/Projekt-11.webp",
          "images/projects/Projekt-12.webp",
          "images/projects/Projekt-13.webp",
          "images/projects/Projekt-15.webp",
          "images/projects/Projekt-16.webp",
        ],
      },

      thumbImage: "images/projects/Projekt-18.webp",

      categories: [filters.DETAILED],
    },

    {
      title: "DopeCode.dev",
      type: types.DOCUMENT,
      document: {
        projectInfo: "Professional portfolio website.",
        client: "My own professional website",
        technologies: "React, Bootstarp, SCSS, JavaSctipt",
        industry: "Frontend Development",
        date: "March, 2023",
        url: {
          name: "https://dopecode.dev",
          link: "https://dopecode.dev",
        },

        sliderImages: [
          "images/projects/project-1-3.webp",
          "images/projects/project-1-2.webp",
        ],
      },

      thumbImage: "images/projects/project-1.webp",

      categories: [filters.DETAILED],
    },
    {
      // title: "Viaimages",
      // type: types.DOCUMENT,
      // document: {
      //   projectInfo:
      //     "Created this website some time ago to improve my skills in React. It is a social website to search for images.",
      //   client: "Social website to search for images",
      //   technologies: "React, CSS, JavaScript",
      //   industry: "Social",
      //   date: "March, 2022",
      title: "Clima Track",
      type: types.DOCUMENT,
      document: {
        projectInfo:
          "A simple Weather Dashboard application with geolocation-based weather data retrieval. Users can search for cities worldwide to get weather details. Includes a feature to save favorite locations for quick access.",
        client: "Private space",
        technologies:
          "React Native, Expo 52,  AsyncStorage, OpenWeatherMap API, Geolocation API",
        industry: "Weather & Location-Based Services",
        date: "February, 2025",
        // url: {
        //   name: "Viaimages",
        //   link: "",
        //   // name: "https://viaimages.eu/",
        //   // link: "https://viaimages.eu/",
        // },

        sliderImages: [
          // "images/projects/project-4-h4.webp",
          // "images/projects/project-4-h8.webp",
          // "images/projects/project-4-h5.webp",
          // "images/projects/project-4-h6.webp",
          // "images/projects/project-4-10.webp",
          // "images/projects/project-4-h9.webp",
          // "images/projects/project-4-h7.webp",
          // "images/projects/Projekt1.webp",
          "images/projects/Projekt2.webp",
          "images/projects/Projekt3.webp",
          "images/projects/Projekt4.webp",
          "images/projects/Projekt5.webp",
        ],
      },

      thumbImage: "images/projects/Projekt1.webp",

      categories: [filters.DETAILED],
    },
    {
      title: "Crazy Color Bucket List",
      type: types.DOCUMENT,
      document: {
        projectInfo:
          "I created this website as a workout project to improve my skills in Vue, Vuetify, ASP.NET and MS SQL.  I was learning it then because they were skills needed at work. It is a tool to create a list of thinks to.",
        client: "Private space",
        technologies: "Vue, Vuetify, ASP,NET Core 6.0, MS SQL Sever",
        industry: "Social",
        date: "May, 2022",
        // url: {
        //   name: "Viaimages",
        //   link: "",
        // },

        sliderImages: [
          "images/projects/project-5-2.webp",
          "images/projects/project-5-3.webp",
          "images/projects/project-5-5.webp",
          "images/projects/project-5-4.webp",
        ],
      },

      thumbImage: "images/projects/project-5.webp",

      categories: [filters.DETAILED],
    },
    {
      title: "Familiarcountries",
      type: types.DOCUMENT,
      document: {
        projectInfo:
          "I created this website for my son to discover the world, the planet Earth. It is my first project ever. Education App for kids. Made in Vanilla JS.",
        client: "Education Website",
        technologies: "JavaScript, SCSS, HTML",
        industry: "Education",
        date: "Feb, 2021",
        // url: {
        //   name: "",
        //   link: "",
        //   // name: "https://familiarcountries.eu/",
        //   // link: "https://familiarcountries.eu/",
        // },

        sliderImages: [
          "images/projects/project-3.webp",
          "images/projects/project-3-2.webp",
          "images/projects/project-3-h1.webp",
          "images/projects/project-3-h2.webp",
        ],
      },

      thumbImage: "images/projects/project-3-0.webp",

      categories: [filters.DETAILED],
    },
    {
      title: "Mindsspace",
      type: types.DOCUMENT,
      document: {
        projectInfo:
          "It is my private blog website. I also used Heroku, Vercel, Strapi and Claudinary for this project. Unfortunately Heroku stopped to offer free Postgresql database, so single posts are not visible now.",
        client: "Private blog (not working now)",
        technologies: "Next (SSR), Tailwind, SCSS, JavaScript",
        industry: "Private space",
        date: "July, 2021",
        // url: {
        //   name: "",
        //   link: "",
        //   // name: "https:/www.mindsspace.eu",
        //   // link: "https:/www.mindsspace.eu",
        // },

        sliderImages: [
          "images/projects/project-2.webp",
          "images/projects/project-2-2.webp",
        ],
      },

      thumbImage: "images/projects/project-2-h1.webp",

      categories: [filters.DETAILED],
    },
    // {
    //   title: "Bookstore",
    //   type: types.DOCUMENT,
    //   document: {
    //     projectInfo:
    //       "It was recruitment task. I used Redux and Redux Thunk in this small project. Link to back-end repository for this project is attached to my CV in PDF file and it is required for the application to work properly. The PDF file is available for download in the resume section.",
    //     client: "One of the recruitment task",
    //     technologies: "React, Bootstrap, SCSS, JavaScript, Redux, Redux Thunk",
    //     industry: "My future employer",
    //     date: "June, 2022",
    //     // url: {
    //     //   name: "Front-end code repository",
    //     //   link: "https://gitfront.io/r/user-6891110/dfTyuPX98tBm/B-o-o-k-s-t-o-r-e-front-gitfront/ ",
    //     // },
    //     sliderImages: [
    //       "images/projects/project-6-h2.webp",
    //       // "images/projects/project-6-h3.webp",
    //       "images/projects/project-6-h4.webp",
    //     ],
    //   },

    //   thumbImage: "images/projects/project-6-h1.webp",

    //   categories: [filters.DETAILED],
    // },
    // {
    //   title: "Mockups Design 1",
    //   type: types.IMAGE,

    //   thumbImage: "images/projects/project-2.jpg",

    //   categories: [filters.MOCKUPS],
    // },
    // {
    //   title: "YouTube Video",
    //   type: types.VIDEO,
    //   video: {
    //     vimeo: false,
    //     id: "PMNnEEEacCg",
    //   },
    //   thumbImage: "images/projects/project-3.jpg",

    //   categories: [filters.YOUTUBE],
    // },
    // {
    //   title: "Vimeo Video",
    //   type: types.VIDEO,
    //   video: {
    //     vimeo: true,
    //     id: "259411563",
    //   },

    //   thumbImage: "images/projects/project-4.jpg",
    //   categories: [filters.VIMEO],
    // },
    // {
    //   title: "Detailed Project 2",
    //   type: types.DOCUMENT,
    //   document: {
    //     projectInfo:
    //       "Quidam lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure. Lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure.",
    //     client: "Ruby Clinton",
    //     technologies: "iOS, HTML5, CSS3, PHP, Java",
    //     industry: "Art & Design",
    //     date: "July 16, 2019",
    //     url: {
    //       name: "www.example.com",
    //       link: "https://www.example.com",
    //     },
    //     sliderImages: [
    //       "images/projects/project-1.jpg",
    //       "images/projects/project-2.jpg",
    //     ],
    //   },
    //   thumbImage: "images/projects/project-5.jpg",
    //   categories: [filters.DETAILED],
    // },
    // {
    //   title: "Mockups Design 2",
    //   type: types.IMAGE,

    //   thumbImage: "images/projects/project-6.jpg",

    //   categories: [filters.MOCKUPS],
    // },
  ];

  // initialize an Isotope object with configs
  useEffect(() => {
    isotope.current = new Isotope(".portfolio-filter", {
      itemSelector: ".filter-item",
      layoutMode: "masonry",
      originLeft: !isRtl,
    });

    // cleanup
    return () => {
      isotope.current.destroy();
    };
  }, []);

  // handling filter key change
  useEffect(() => {
    if (imagesLoaded) {
      filterKey === "*"
        ? isotope.current.arrange({ filter: `*` })
        : isotope.current.arrange({ filter: `.${filterKey}` });
    }
  }, [filterKey, imagesLoaded]);

  const handleFilterKeyChange = (key) => () => setFilterKey(key);

  const getKeyByValue = (value) => {
    return Object.keys(filters).find((key) => filters[key] === value);
  };

  const getFilterClasses = (categories) => {
    if (categories.length > 0) {
      let tempArray = [];
      categories.forEach((category, index) => {
        tempArray.push(getKeyByValue(category));
      });
      return tempArray.join(" ");
    }
  };

  return (
    <>
      <section id="portfolio" className={"section bg-light"}>
        <div className={"container"}>
          {/* Heading */}
          <p className="text-center mb-2 wow fadeInUp">
            <span className="bg-primary text-dark px-4 text-5">Portfolio</span>
          </p>
          <h2 className="text-10 fw-600 text-center mb-5 wow fadeInUp">
            Some of my private Projects
          </h2>
          {/* Heading end*/}
          {/* Filter Menu */}
          <ul
            className={
              "portfolio-menu nav nav-tabs fw-600 justify-content-start justify-content-md-center border-bottom-0 mb-5 wow fadeInUp"
            }
          >
            <li className="nav-item">
              <button
                className={"nav-link " + (filterKey === "*" ? "active" : "")}
                onClick={handleFilterKeyChange("*")}
              >
                All
              </button>
            </li>
            {Object.keys(filters).map((oneKey, i) => (
              <li className="nav-item" key={i}>
                <button
                  className={
                    "nav-link " + (filterKey === oneKey ? "active" : "")
                  }
                  onClick={handleFilterKeyChange(oneKey)}
                >
                  {filters[oneKey]}
                </button>
              </li>
            ))}
          </ul>
          {/* Filter Menu end */}
          <div className="portfolio wow fadeInUp">
            <div className="row portfolio-filter filter-container g-4">
              {projectsData.length > 0 &&
                projectsData.map((project, index) => (
                  <div
                    className={
                      "col-sm-6 col-lg-4 filter-item " +
                      getFilterClasses(project.categories)
                    }
                    key={index}
                  >
                    <div
                      style={{ display: "flex", justifyContent: "center" }}
                      className="portfolio-box"
                    >
                      {/* //~~ FIRST WAY OF IMPLEMENTATION SHOWING THE PROJECT ON HOVERING THE IMAGE */}
                      {/* <div className="portfolio-img view view-ninth">
                        <img
                          onLoad={() => {
                            setimagesLoaded(imagesLoaded + 1);
                          }}
                          className="img-fluid d-block portfolio-image"
                          src={project.thumbImage}
                          alt=""  
                        />
                          	<div className="mask mask-1"></div>
	                        <div className="mask mask-2"></div>
                          <div className="content">
                          <h2> {project?.title}</h2>
                         <p> {project?.document.technologies}</p>
                        <div
                          // className="portfolio-overlay"
                          onClick={() => {
                            setSelectedProjectDetails(projectsData[index]);
                            setIsOpen(true);
                          }}
                        >
                          <button className="popup-ajax stretched-link border-0 p-0 info">
                            {" "}
                          </button>
                          <div className="portfolio-overlay-details"> */}

                      {/* //~~ SECEND WAY OF IMPLEMENTATION SHOWING THE PROJECT ON HOVERING THE IMAGE */}

                      {/* <div className="portfolio-img view view-sixth">
                        <img
                          onLoad={() => {
                            setimagesLoaded(imagesLoaded + 1);
                          }}
                          className="img-fluid d-block portfolio-image"
                          src={project.thumbImage}
                          alt=""
                        />
                        <div className="mask">
                          <h2> {project?.title}</h2>
                          <p> {project?.document.technologies}</p>
                          <div
                            // className="portfolio-overlay"
                            onClick={() => {
                              setSelectedProjectDetails(projectsData[index]);
                              setIsOpen(true);
                            }}
                          >
                            <button className="popup-ajax stretched-link border-0 p-0 info">
                              {" "}
                            </button>
                            <div className="portfolio-overlay-details"> */}
                      {/* //~~ THIRD WAY OF IMPLEMENTATION SHOWING THE PROJECT ON HOVERING THE IMAGE */}
                      <div className="portfolio-img view view-first">
                        <img
                          onLoad={() => {
                            setimagesLoaded(imagesLoaded + 1);
                          }}
                          className="img-fluid d-block portfolio-image"
                          src={project.thumbImage}
                          alt=""
                        />
                        <div className="mask">
                          <h2> {project?.title}</h2>
                          <p> {project?.document.technologies}</p>
                          <div
                            // className="portfolio-overlay"
                            onClick={() => {
                              setSelectedProjectDetails(projectsData[index]);
                              setIsOpen(true);
                            }}
                          >
                            <button className="popup-ajax stretched-link border-0 p-0 info">
                              {" "}
                            </button>
                            <p
                              style={{
                                color: "#E8A87C",
                                fontSize: "1.3rem",
                                position: "absolute",
                                bottom: "0",
                                left: "50%",
                                transform: "translateX(-50%)",
                              }}
                            >
                              click on the box
                            </p>
                            <div className="portfolio-overlay-details">
                              {/* <p className="text-primary text-8">
                              {project.type === types.DOCUMENT && (
                                <i className="fas fa-file-alt"></i>
                              )}
                              {project.type === types.IMAGE && (
                                <i className="fas fa-image"></i>
                              )}
                              {project.type === types.VIDEO && (
                                <i className="fas fa-video"></i>
                              )}
                            </p> */}
                              {/* <h5 className="text-white text-5">
                              {project?.title}
                            </h5> */}
                              {/* <span className="text-light">Category</span> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </section>
      {/* Modal */}
      {isOpen && (
        <ProjectDetailsModal
          projectDetails={selectedProjectDetails}
          setIsOpen={setIsOpen}
        ></ProjectDetailsModal>
      )}
    </>
  );
};

export default Portfolio;
